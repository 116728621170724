import {http} from './config';

export default {

    index: (filter, page, term = null, filters = {}) => {
        return http.get('v1/documentos', {params: {filter: filter, page: page, term:term, filters}});
    },
    show: (id) => {
        return http.get('v1/documentos/' + id);
    },
    cancel: (id, accessToken) => {
        return http.patch('v1/documentos/cancelar/' + id,[], {headers: {'access-token': [accessToken] }});
    },
    indexAt: (untilDay) => {
        return http.get(`v1/documentos/desde?until=${untilDay}`, {
        });
    },
    getRawData: () => {
        return http.get('v1/documentos/dados');
    },

}
