<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "loadingScreen",
  props: ["isLoading"]
};
</script>

<style>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center; 
  }
  .loader .spinner-border {
    width: 3rem;
    height: 3rem;
    color: #ffffff; 
}

.fadeout {
  animation: fadeout 1.5s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>