<template>
    <div class="page-header">
        <li class="first">{{ t('DOCUMENTS.DOCUMENTS') }}</li>
        <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2 col-2"></div>
        <div v-if="this.$route.name !== 'Home' && haveFilter" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5">
            <Select2 :settings="setting" id="select_document" @select="viewDocument($event.original_file_url)"
                     name="select_document"/>
        </div>
        <div class="row">
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-end">
            <div class="custom-search w-100" v-if="haveSearch">
                <input type="text" class="search-query w-100" v-model="search" placeholder="Pesquisar"
                       @keypress="emitWithEnter($event)">
                <i class="icon-search1" @click="$emit('searching', search)"></i>
            </div>
            <a class="btn btn-primary" id="filter" data-toggle="modal" data-target="#customModalTwo"
               @click="$emit('filter')" v-if="filter">
                Filtro
            </a>
        </div>
    </div>
</template>
<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Select2 from 'vue3-select2-component';
import Helper from '@/services/helper';

export default {
    components: {
        Select2
    },
    props: {
        typeOfFind: {
            type: String,
            default: '',
            required: false
        },
        filter:{
           default: false,
           type: Boolean,
        },
        haveFilter: {
            default: false,
            type: Boolean,
        },
        haveSearch: {
            default: false,
            type: Boolean,
        }
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },

    data() {
        return {
            search: null,
            setting: {
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 3 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                width: '100%',
                placeholder: 'Buscar Documento Original Pelo Nome',
                minimumInputLength: 3,
                allowClear: true,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/v1/documentos/listar/${this.typeOfFind}`,
                    headers: {
                        'company-id': localStorage.getItem('company_id'),
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        'refresh-token': localStorage.getItem('refresh_token'),
                    },
                    dataType: 'json',
                    data: params => {
                        return {
                            term: params.term
                        }
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            return {
                                results: data.map(({id, text: text, original_file_url: original_file_url}) => ({
                                    id,
                                    text,
                                    original_file_url
                                }))
                            };
                        } else {
                            return {results: ""};
                        }
                    },
                }
            }
        }
    },

    methods: {
        emitWithEnter(log) {
            if (log.key == 'Enter') {
                this.$emit('searching', this.search);
            }
        },

        viewDocument(originalDocumentUrl) {
            Helper.viewDocument(originalDocumentUrl);
        },
    }
}
</script>

<style>
.first {
    border-radius: 0px;
    background: transparent;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    align-items: center;
}

.btn-custom {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: white !important;
    color: black !important;
    border: 1px solid #0e5e4e;
}

.bg-btn:hover, .bg-btn:focus {
    background-color: #0e5e4e !important;
    color: white !important;
}

.search-query::placeholder {
    color: #0e5e4e;
    opacity: 0.9
}
</style>
